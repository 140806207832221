import axios from 'axios'

export const statusAplikasiMixin = {
    data () {
        return {
            detailStatusAplikasi: []
        }
    },
    methods: {
        // data detail status aplikasi
        getDetailStatusAplikasi (id) {
            axios.get('/api/pengaturan/status-aplikasi/' + id).then(response => {
                this.detailStatusAplikasi = response.data.data
                this.form.fill(this.detailStatusAplikasi)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}

export default statusAplikasiMixin